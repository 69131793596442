import { React, useState, useEffect } from "V3";
import { Select as OriginalSelect, InputLabel, MenuItem, FormControl, IconButton } from "@material-ui/core"; // Import additional components
import ClearIcon from "@material-ui/icons/Clear";
import Checkbox from "@mui/material/Checkbox";

export const Select = ({
    state,
    onChange,
    options = [],
    autoWidth = false,
    size = "small",
    sx = { width: "100%" },
    name = "UnnamedField",
    label = "Unnamed Field",
    multiple = false,
    required = false,
    isClearable = false,
    useCheckbox = false,
    getItemValue = (option) => option,
    getItemLabel = (option) => option,
    useValue,
    value,
    renderValue,
    functionalOptions,
    customOptions,
    ...props
}) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (!open) document.getElementsByTagName("body")[0].setAttribute("style", "overflow-y:scroll;");
    }, [open]);

    const handleChange = (e) => {
        const value = e?.target?.value || "";
        const finalValue = typeof value === "string" ? value.split(",") : value;
        onChange(finalValue, name);
    };

    const handleClear = () => {
        onChange(multiple ? [] : "", name);
    };

    /**
     * Return
     */
    return (
        <FormControl sx={{ ...{ minWidth: 120 }, ...sx }} size={size}>
            <InputLabel id={name} required={required}>
                {label}
            </InputLabel>
            <OriginalSelect
                labelId={name}
                id={name}
                open={open}
                displayEmpty
                onClose={() => {
                    setOpen(false);
                    setTimeout(() => document.activeElement.blur(), 0);
                }}
                onOpen={() => setOpen(true)}
                value={useValue ? value || [] : state && state[name] ? state[name] : []}
                label={label}
                size={size}
                onChange={(e) => {
                    handleChange(e);
                }}
                autoWidth={autoWidth}
                required={required}
                multiple={multiple}
                endAdornment={
                    isClearable && state[name] ? (
                        <IconButton style={{ marginRight: "13px" }} onClick={handleClear}>
                            <ClearIcon style={{ width: "20px", height: "20px" }} />
                        </IconButton>
                    ) : null
                }
                renderValue={renderValue}
            >
                {functionalOptions?.map((functionalOption, index) => {
                    return (
                        <MenuItem
                            key={index}
                            value={functionalOption.label}
                            style={{ fontWeight: 400, maxHeight: 30 }}
                            onClick={(event) => {
                                functionalOption.onClick(options, value || state?.[name]);
                            }}
                        >
                            {functionalOption.label}
                        </MenuItem>
                    );
                })}
                {options?.map((option, index) => {
                    if (customOptions) {
                        return customOptions({
                            option,
                            index,
                            getItemValue,
                            getItemLabel,
                            MenuItem,
                            Checkbox,
                            useValue,
                            value,
                            state,
                            useCheckbox,
                        });
                    }

                    const optionValue = getItemValue(option);
                    const optionLabel = getItemLabel(option);
                    const isSelected = useValue
                        ? value?.indexOf(optionValue) > -1
                        : state?.[name]?.indexOf?.(optionValue) > -1;

                    return (
                        <MenuItem
                            key={optionValue}
                            value={optionValue}
                            style={isSelected ? { fontWeight: 700, maxHeight: 30 } : { fontWeight: 400, maxHeight: 30 }}
                        >
                            {useCheckbox && <Checkbox checked={isSelected} size="small" />}
                            {optionLabel}
                        </MenuItem>
                    );
                })}
            </OriginalSelect>
        </FormControl>
    );
};
