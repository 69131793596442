import { typeOfAdvanced, _ } from "V3";

export const catchError = ({ arr, res }) => {
    console.log(res);
    const newArr =
        arr && arr.length > 0
            ? arr
            : [
                  res?.response?.data?.error,
                  res?.response?.data?.errors,
                  res?.response?.data?.data?.error,
                  res?.response?.data?.data?.errors,
                  res?.responseData?.data?.error,
                  res?.responseData?.data?.errors,
                  res?.responseData?.data?.data?.error,
                  res?.responseData?.data?.data?.errors,
                  res?.error,
                  res?.errors,
              ];

    let error;

    _.forEach(newArr, (val) => {
        if (val === undefined || val === null) return;

        console.log(val);
        const type = typeOfAdvanced(val);

        if (type === "string") {
            error = val;
            return false;
        } else if (type === "array" && val.length > 0) {
            error = val[0];
            return false;
        } else if (type === "object") {
            let values = "";
            if (val.errors) {
                Object.keys(val.errors).forEach((key) => {
                    values += `${key}: ${val.errors[key]}\n`;
                });
            } else {
                try {
                    Object.keys(val).forEach((key) => {
                        values += `${key}: ${val[key]}\n`;
                    });
                } catch (e) {
                    values = "Error: Unable to parse error object";
                }
            }
            error = values.trim();
            return false;
        }
    });

    console.log(error);
    if (error) return error.trim();
    if (res?.responseData?.statusText) return res.responseData.statusText;
    if (res?.response?.statusText) return res.response.statusText;
    if (res?.message) return res.message;

    return "Undefined error...";
};
