/*

<IconLib
  backgroundColor="#780000"
  backgroundHoverColor="#00f7ff"
  color="blue"
  hoverColor="red"
  icon={ IconHamburger }
  onClick={()=>{}}
  width={45}
/>

*/

import { React, useState } from "V3";
import "./IconLib.scss";

//-------------------------------------------------------------
// 		ICON WRAPPER
//-------------------------------------------------------------

export const Icon = ({
    icon = IconHamburger,
    iconName,
    color,
    hoverColor,
    backgroundColor,
    backgroundHoverColor,
    width = 2,
    onClick,
    title = "",
    divStyle,
    svgStyle,
    ...props
}) => {
    const [isHover, setIsHover] = useState(false);
    const IncomingSvg = icon;

    return (
        <div
            tabIndex={onClick ? 0 : ""}
            className={"IconComponent" + (onClick ? " clickable" : "")}
            title={title}
            style={{
                ...divStyle,
                width: width + "px",
                height: width + "px",
                backgroundColor: !isHover ? backgroundColor || false : backgroundHoverColor || false,
                ...props,
            }}
            onMouseEnter={() => {
                if (onClick) setIsHover(true);
            }}
            onMouseLeave={() => {
                if (onClick) setIsHover(false);
            }}
            onClick={!onClick ? null : () => onClick()}
        >
            <svg
                className="IconComponent_svgWrapper"
                fill={!isHover ? color || "white" : hoverColor || "blue"}
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xlink="http://www.w3.org/1999/xlink"
                width={width + "px"}
                viewBox="0 0 20 20"
                space="preserve"
                style={{ ...svgStyle }}
            >
                <IncomingSvg />
                <rect style={{ fill: "rgba(0,0,0,0)" }} width="20" height="20" />
            </svg>
        </div>
    );
};

//-------------------------------------------------------------
// 		ICON LIB
//-------------------------------------------------------------

const IconHamburger = () => (
    <path d="M15.31,6.24V7.57H4.69V6.24ZM4.69,10.66H15.31V9.34H4.69Zm0,3.1H15.31V12.43H4.69Z" />
);
const IconHome = () => <path d="M10,6.26,6.61,8.89v4.85H8.94V10.81h2.12v2.93h2.33V8.89Z" />;
const IconEdit = () => (
    <path d="M3,18h14c0.6,0,1,0.4,1,1s-0.4,1-1,1H3c-0.6,0-1-0.4-1-1S2.4,18,3,18z M2,13L12,3l3,3L5,16H2V13z M13,2l2-2l3,3 l-2,2L13,2z" />
);
const IconWarningSign = () => (
    <React.Fragment>
        <path d="M10,5.51l0,0L4.77,14.31a.12.12,0,0,0,0,.14s0,0,0,0H15.2l0,0a.14.14,0,0,0,0-.14L10.09,5.55l0,0a.09.09,0,0,0-.1,0Zm5.93,8.42a.94.94,0,0,1,.13.43.87.87,0,0,1-.86.89H4.85a.82.82,0,0,1-.43-.12.87.87,0,0,1-.3-1.2L9.26,5.17a.86.86,0,0,1,.32-.31.85.85,0,0,1,1.16.31Z" />
        <path d="M9.25,12.92a.75.75,0,1,1,.75.75h0a.75.75,0,0,1-.75-.75Zm.07-4.5a.68.68,0,0,1,1.35-.15.38.38,0,0,1,0,.15l-.26,2.63a.42.42,0,0,1-.45.38.41.41,0,0,1-.37-.38Z" />
    </React.Fragment>
);
const IconWarning = () => (
    <path d="M8.55,14.34A1.45,1.45,0,1,1,10,15.79h0a1.45,1.45,0,0,1-1.45-1.45ZM8.7,5.66a1.31,1.31,0,1,1,2.6,0l-.51,5.07a.8.8,0,0,1-1.59,0Z" />
);
const IconClose = () => (
    <path
        d="M11.06,10l4.71-4.71c0.29-0.29,0.29-0.77,0-1.06s-0.77-0.29-1.06,0L10,8.94L5.29,4.23
	c-0.29-0.29-0.77-0.29-1.06,0s-0.29,0.77,0,1.06L8.94,10l-4.71,4.71c-0.29,0.29-0.29,0.77,0,1.06c0.15,0.15,0.34,0.22,0.53,0.22
	s0.38-0.07,0.53-0.22L10,11.06l4.71,4.71c0.15,0.15,0.34,0.22,0.53,0.22s0.38-0.07,0.53-0.22c0.29-0.29,0.29-0.77,0-1.06L11.06,10z"
    />
);
const IconMagnifier = () => (
    <path
        d="M17.17,15.76l-3.85-3.85l0,0c-0.03-0.03-0.07-0.05-0.11-0.07c0.69-0.96,1.1-2.14,1.1-3.41
	c0-3.25-2.64-5.89-5.89-5.89S2.53,5.17,2.53,8.42s2.64,5.89,5.89,5.89c1.27,0,2.45-0.41,3.41-1.1c0.03,0.04,0.04,0.08,0.07,0.11
	l3.85,3.85c0.39,0.39,1.02,0.39,1.42,0C17.57,16.78,17.57,16.15,17.17,15.76z M8.42,3.81c2.54,0,4.61,2.07,4.61,4.61
	s-2.07,4.61-4.61,4.61s-4.61-2.07-4.61-4.61S5.88,3.81,8.42,3.81z"
    />
);
const IconColumns = () => (
    <path
        d="M16.98,2.49h-3.76h-1.07H7.85H6.78H3.02c-0.89,0-1.61,0.72-1.61,1.61V15.9c0,0.89,0.72,1.61,1.61,1.61h13.95
	c0.89,0,1.61-0.72,1.61-1.61V4.1C18.59,3.21,17.87,2.49,16.98,2.49z M12.15,3.56v12.88H7.85V3.56H12.15z M2.49,15.9V4.1
	c0-0.3,0.24-0.54,0.54-0.54h3.76v12.88H3.02C2.73,16.44,2.49,16.2,2.49,15.9z M17.52,15.9C17.52,15.91,17.52,15.91,17.52,15.9
	c0,0.3-0.24,0.54-0.54,0.54h-3.76V3.56h3.76c0.3,0,0.54,0.24,0.54,0.54V15.9z"
    />
);
const IconCardView = () => (
    <path
        d="M12.45,4.5H7.56v4.89h4.89V4.5z M11.22,8.16H8.78V5.72h2.44V8.16z M13.67,4.5v4.89h4.89V4.5H13.67z M17.34,8.16
	h-2.44V5.72h2.44V8.16z M7.56,15.5L7.56,15.5h4.89v-4.89H7.56V15.5z M8.78,11.84h2.44v2.44H8.78V11.84z M1.44,9.39h4.89V4.5H1.44
	V9.39z M2.67,5.72h2.44v2.44H2.67V5.72z M13.67,15.5h4.89v-4.89h-4.89V15.5z M14.89,11.84h2.44v2.44h-2.44V11.84z M1.44,15.5h4.89
	v-4.89H1.44V15.5z M2.67,11.84h2.44v2.44H2.67V11.84z"
    />
);
const IconSwitch = () => (
    <path
        d="M13,11.5c-0.01,0.41-0.36,0.74-0.77,0.73H6.6l0.84,0.84c0.29,0.29,0.29,0.76,0,1.06s-0.76,0.29-1.06,0
	l-2.01-2.01c-0.22-0.14-0.35-0.38-0.35-0.63c0-0.41,0.33-0.75,0.75-0.75h7.47c0.01,0,0.03,0,0.04,0C12.69,10.75,13.01,11.09,13,11.5
	z M15.63,7.89l-2.01-2.01c-0.29-0.29-0.76-0.29-1.06,0c-0.29,0.29-0.29,0.76,0,1.06l0.84,0.84H7.76C7.35,7.78,7.03,8.13,7.04,8.54
	c0.01,0.4,0.33,0.71,0.73,0.73h7.47h0c0.41,0,0.75-0.33,0.75-0.75C15.98,8.26,15.85,8.02,15.63,7.89z"
    />
);
const IconPlus = () => (
    <path
        d="M16.65,10c0,0.41-0.34,0.75-0.75,0.75h-5.15v5.15c0,0.41-0.34,0.75-0.75,0.75s-0.75-0.34-0.75-0.75v-5.15H4.1
	c-0.41,0-0.75-0.34-0.75-0.75S3.69,9.25,4.1,9.25h5.15V4.1c0-0.41,0.34-0.75,0.75-0.75s0.75,0.34,0.75,0.75v5.15h5.15
	C16.31,9.25,16.65,9.59,16.65,10z"
    />
);
const IconArrowRight = () => (
    <path
        d="M7.79,3.37c0.29,0,0.56,0.13,0.74,0.35L13.1,9.4c0.29,0.35,0.29,0.85,0,1.2l-4.74,5.68
		c-0.33,0.4-0.93,0.46-1.34,0.12c-0.4-0.33-0.46-0.93-0.12-1.34L11.14,10L7.05,4.92c-0.33-0.4-0.28-1,0.12-1.33
		C7.34,3.44,7.56,3.37,7.79,3.37z"
    />
);
const IconArrowDown = () => (
    <path
        d="M16.63,7.79c0,0.29-0.13,0.56-0.35,0.74L10.6,13.1c-0.35,0.29-0.85,0.29-1.2,0L3.71,8.36
		c-0.4-0.33-0.46-0.93-0.12-1.34c0.33-0.4,0.93-0.46,1.34-0.12L10,11.14l5.08-4.09c0.4-0.33,1-0.28,1.33,0.12
		C16.56,7.34,16.63,7.56,16.63,7.79z"
    />
);
const IconArrowUp = () => (
    <path
        d="M3.37,12.21c0-0.29,0.13-0.56,0.35-0.74L9.4,6.9c0.35-0.29,0.85-0.29,1.2,0l5.68,4.74
		c0.4,0.33,0.46,0.93,0.12,1.34c-0.33,0.4-0.93,0.46-1.34,0.12L10,8.86l-5.08,4.09c-0.4,0.33-1,0.28-1.33-0.12
		C3.44,12.66,3.37,12.44,3.37,12.21z"
    />
);
const IconArrowLeft = () => (
    <path
        d="M12.21,16.63c-0.29,0-0.56-0.13-0.74-0.35L6.9,10.6c-0.29-0.35-0.29-0.85,0-1.2l4.74-5.68
		c0.33-0.4,0.93-0.46,1.34-0.12c0.4,0.33,0.46,0.93,0.12,1.34L8.86,10l4.09,5.08c0.33,0.4,0.28,1-0.12,1.33
		C12.66,16.56,12.44,16.63,12.21,16.63z"
    />
);
const IconCheck = () => (
    <path
        d="M14.89,6.34c-0.28-0.29-0.75-0.29-1.04-0.01c0,0,0,0-0.01,0.01l-5.41,5.41L6.17,9.31
		c-0.28-0.3-0.75-0.32-1.05-0.04s-0.32,0.75-0.04,1.05l3.32,3.56l6.49-6.48c0.29-0.28,0.3-0.75,0.02-1.04
		C14.9,6.35,14.9,6.35,14.89,6.34L14.89,6.34z"
    />
);
const IconTime = () => (
    <path
        d="M10,4.57c1.45-0.02,2.84,0.55,3.85,1.59c1.04,1,1.61,2.39,1.59,3.83
	c0.02,1.44-0.56,2.83-1.59,3.83c-1.01,1.04-2.4,1.62-3.85,1.59c-1.45,0.02-2.84-0.55-3.85-1.59c-1.04-1-1.61-2.39-1.59-3.83
	C4.54,8.56,5.12,7.17,6.15,6.17C7.16,5.13,8.55,4.55,10,4.57z M10,14.36c1.16,0.02,2.27-0.44,3.07-1.27
	c0.83-0.81,1.29-1.92,1.27-3.08c0.02-1.16-0.44-2.28-1.27-3.08c-0.8-0.83-1.91-1.29-3.07-1.27C8.85,5.63,7.73,6.09,6.93,6.92
	C6.1,7.72,5.64,8.84,5.66,10c-0.02,1.16,0.44,2.28,1.27,3.09C7.73,13.91,8.85,14.38,10,14.36z M10.27,7.27v2.85l2.45,1.45
	l-0.41,0.69l-2.85-1.73V7.27L10.27,7.27L10.27,7.27z"
    />
);
const IconUpload = () => (
    <path
        d="M7.63,7.77c-0.15-0.15-0.15-0.4,0-0.56l2.09-2.09c0,0,0,0,0,0c0.15-0.15,0.4-0.15,0.56,0l2.09,2.09
	c0,0,0.01,0.01,0.01,0.01c0.15,0.16,0.14,0.41-0.01,0.56c-0.16,0.15-0.41,0.14-0.56-0.01L10.4,6.35v5.87c0,0.22-0.17,0.4-0.39,0.4
	c-0.22,0-0.4-0.17-0.4-0.39c0,0,0-0.01,0-0.01V6.35L8.19,7.77c0,0,0,0,0,0C8.04,7.93,7.79,7.93,7.63,7.77L7.63,7.77
	C7.63,7.77,7.63,7.77,7.63,7.77z M15.93,10.26c-0.22,0-0.4,0.17-0.4,0.39v2.76c0,0.44-0.35,0.79-0.79,0.79H5.26
	c-0.44,0-0.79-0.35-0.79-0.79v-2.76c0-0.22-0.18-0.39-0.39-0.39c-0.22,0-0.39,0.18-0.39,0.39v2.76c0,0.87,0.71,1.58,1.58,1.58h9.48
	c0.87,0,1.58-0.71,1.58-1.58v-2.76C16.31,10.44,16.14,10.27,15.93,10.26z"
    />
);
const IconDot = () => <circle cx="10" cy="10" r="5.5" />;
const IconRect = () => <rect x="5" y="5" width="10" height="10" />;
const IconAsterisk = () => (
    <path d="M10,3.51a.81.81,0,0,1,.81.81V8.59l3.7-2.13a.81.81,0,0,1,.81,1.41L11.62,10l3.7,2.14a.81.81,0,0,1,.3,1.1.82.82,0,0,1-1.11.3h0l-3.7-2.13v4.27a.81.81,0,0,1-1.62,0V11.41l-3.7,2.13a.81.81,0,1,1-.81-1.41L8.38,10,4.68,7.86a.81.81,0,1,1,.81-1.4l3.7,2.13V4.32A.81.81,0,0,1,10,3.51Z" />
);
const IconBell = () => (
    <path d="M14.28,12.91l-.1-.09a4.44,4.44,0,0,1-.72-.84,3.7,3.7,0,0,1-.39-1.38V9.18a3.09,3.09,0,0,0-2.71-3.09V5.72a.39.39,0,0,0-.37-.4.38.38,0,0,0-.39.38v.4A3.1,3.1,0,0,0,6.92,9.18V10.6A3.7,3.7,0,0,1,6.53,12a4.39,4.39,0,0,1-.71.84l-.1.09v.81h8.56ZM9.24,14a.77.77,0,0,0,.86.65.75.75,0,0,0,.65-.65Z" />
);
const IconThreeDot = () => (
    <path d="M10.75,7A.75.75,0,1,1,10,6.26.76.76,0,0,1,10.75,7Zm0,3A.75.75,0,1,1,10,9.25.76.76,0,0,1,10.75,10Zm0,3a.75.75,0,1,1-.75-.75A.76.76,0,0,1,10.75,13Z" />
);
const IconTrash = () => (
    <path d="M4.7,16.1c0,1,0.8,1.7,1.8,1.7h7c1,0,1.7-0.8,1.8-1.7V6.1H4.7V16.1z M16.6,3.4h-3.3l-1.1-1.3H7.8 L6.7,3.4H3.4v1.3h13.2V3.4z" />
);
const IconOffer = () => (
    <path d="M17.54,9.66A1.61,1.61,0,0,1,18,10.81a1.47,1.47,0,0,1-.49,1.11l-5.6,5.64a1.68,1.68,0,0,1-1.15.45,1.52,1.52,0,0,1-1.11-.45L2.46,10.34A1.55,1.55,0,0,1,2,9.21V3.57a1.54,1.54,0,0,1,.47-1.11A1.55,1.55,0,0,1,3.59,2h5.6a1.55,1.55,0,0,1,1.13.45ZM4.77,6a1.25,1.25,0,0,0,.87-.34A1.18,1.18,0,0,0,6,4.79,1.22,1.22,0,0,0,4.78,3.57h0a1.08,1.08,0,0,0-.84.36,1.16,1.16,0,0,0-.34.86A1.16,1.16,0,0,0,4.72,6Z" />
);
const IconReset = () => (
    <path d="M11.4,3c-3.9,0-7,3.1-7,7v2.1l-1.5-1.5l-1.5,1.5l4,4l4-4l-1.5-1.5l-1.5,1.5V10c0-2.7,2.2-4.9,4.9-4.9c2.7,0,4.9,2.2,4.9,4.9 s-2.2,4.9-4.9,4.9h-0.5V17h0.5c3.9,0,7-3.1,7-7S15.2,3,11.4,3z" />
);
const IconLink = () => (
    <path d="M13.43,10l1.72,1.72,3.43-3.44a4.85,4.85,0,0,0-6.86-6.86L8.28,4.85,10,6.57l3.43-3.43a2.43,2.43,0,0,1,3.43,3.43ZM10,13.43l1.72,1.72L8.28,18.58a4.85,4.85,0,0,1-6.86-6.86L4.85,8.28,6.57,10,3.14,13.43a2.43,2.43,0,0,0,3.43,3.43h0L10,13.43Zm3.43-5.15a1.21,1.21,0,0,0-1.71-1.71h0L6.57,11.72a1.21,1.21,0,1,0,1.68,1.74l0,0,5.15-5.15Z" />
);
const IconDesctiption = () => (
    <path d="M13.11,0H1.17V20H18.83V5.72L13.11,0Zm1.06,17.55H4.83v-1.33H14.17v1.33Zm0-3.32H4.83v-1.33H14.17v1.33Zm0-3.32H4.83v-1.33H14.17v1.33Zm3.33-4.58h-5V1.33h.06l4.94,4.94v.06Z" />
);
const IconClickOut = () => (
    <path d="M10.3,9.2l-8,5.5c-0.2,0.1-0.1,0.5,0.2,0.5h3c0.2,0,0.3,0.2,0.2,0.4l-1.2,2.1c-0.1,0.1,0,0.3,0.1,0.4l1.4,0.8 c0.1,0.1,0.3,0,0.4-0.1l1.2-2.1c0.1-0.2,0.3-0.2,0.5,0l1.5,2.5c0.1,0.2,0.5,0.1,0.5-0.1l0.8-9.6C10.7,9.2,10.4,9,10.3,9.2z M4.4,11.7c0,0,0-0.1,0-0.1c-0.8-3,1-6.1,4.1-7s6.3,0.9,7.1,4c0.8,3-1,6.2-4.2,7h0L11.2,20c0.5,0,0.9-0.1,1.4-0.2 c5.4-1.5,8.5-6.9,7.1-12.2C18.2,2.1,12.8-1,7.4,0.4C2,1.8-1.1,7.3,0.3,12.6c0.1,0.5,0.3,0.9,0.5,1.3L4.4,11.7L4.4,11.7z" />
);
const IconStarEmpty = () => (
    <path d="M10,1.5c0.1,0,0.3,0.1,0.3,0.2l1.9,5.1 c0.1,0.4,0.5,0.7,1,0.7l0,0l0,0l5.5,0.2c0.1,0,0.2,0.1,0.3,0.1C19,8,19,8.2,18.8,8.3l-4.3,3.4c-0.4,0.3-0.5,0.8-0.4,1.2l1.5,5.2 c0,0.1,0,0.2,0,0.3c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.1,0-0.2-0.1l-4.5-3c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.6,0.2l-4.5,3 c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.2-0.2c0,0-0.1-0.1,0-0.3l1.5-5.2c0.1-0.4,0-0.9-0.4-1.2L1.1,8.3 C1.1,8.3,1,8.2,1,8.1c0-0.2,0.1-0.3,0.4-0.3l5.4-0.2l0,0l0,0c0.4,0,0.8-0.3,1-0.7l1.9-5.1c0-0.1,0.1-0.2,0.2-0.2 C9.9,1.5,10,1.5,10,1.5 M10,0.5c-0.2,0-0.3,0-0.5,0.1C9.2,0.7,8.9,1,8.7,1.3L6.9,6.4c0,0.1-0.1,0.1-0.1,0.1L1.3,6.7 C0.6,6.7,0,7.3,0,8.1c0,0.4,0.2,0.8,0.5,1.1l4.3,3.3c0,0,0.1,0.1,0,0.1l-1.5,5.2c-0.2,0.7,0.2,1.5,0.9,1.7c0.1,0,0.2,0.1,0.4,0.1 c0.3,0,0.5-0.1,0.8-0.2l4.5-3c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0l4.5,3c0.2,0.2,0.5,0.2,0.8,0.2c0.4,0,0.8-0.2,1.1-0.6 c0.2-0.3,0.3-0.8,0.2-1.1l-1.5-5.2c0-0.1,0-0.1,0-0.1l4.3-3.4c0.6-0.4,0.7-1.3,0.3-1.9c-0.2-0.3-0.6-0.5-1.1-0.5l-5.4-0.2 c-0.1,0-0.1,0-0.1-0.1l-1.9-5.1C11.1,0.8,10.5,0.5,10,0.5L10,0.5z" />
);
const IconStarHalf = () => (
    <path d="M19.5,9.1c0.6-0.4,0.7-1.3,0.3-1.9c-0.2-0.3-0.6-0.5-1.1-0.5l-5.4-0.2c-0.1,0-0.1,0-0.1-0.1l-1.9-5.1 c-0.2-0.5-0.7-0.9-1.3-0.9c-0.2,0-0.3,0-0.5,0.1C9.2,0.7,8.9,1,8.7,1.3L6.9,6.4c0,0.1-0.1,0.1-0.1,0.1L1.3,6.7 c-0.4,0-0.7,0.2-0.9,0.4C0.1,7.4,0,7.7,0,8.1c0,0.4,0.2,0.8,0.5,1.1l4.3,3.3c0,0,0.1,0.1,0,0.1l-1.5,5.2c-0.1,0.2-0.1,0.4,0,0.5 c0.1,0.5,0.4,1,1,1.1c0.1,0,0.2,0,0.3,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0.1,0,0.2,0,0.3,0c0,0,0.1,0,0.1,0c0.1,0,0.2-0.1,0.3-0.2l4.5-3 c0,0,0.1,0,0.1,0v0c0,0,0,0,0,0l4.5,3c0.2,0.2,0.5,0.2,0.8,0.2c0.4,0,0.8-0.2,1.1-0.6c0.2-0.3,0.3-0.8,0.2-1.1l-1.5-5.2 c0-0.1,0-0.1,0-0.1L19.5,9.1z M14.2,12.9l1.5,5.2c0,0.1,0,0.2,0,0.3c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.1,0-0.2-0.1l-4.5-3 c-0.2-0.1-0.4-0.2-0.6-0.2V1.5c0.1,0,0.2,0.1,0.3,0.2l1.9,5.1c0.1,0.4,0.5,0.7,1,0.7l0,0l0,0l5.5,0.2c0.1,0,0.2,0.1,0.3,0.1 C19,8,19,8.2,18.8,8.3l-4.3,3.4C14.2,12,14,12.5,14.2,12.9z" />
);
const IconStar = () => (
    <path d="M19.46,9.11a1.34,1.34,0,0,0-.78-2.41l-5.43-.2a.14.14,0,0,1-.11-.1L11.26,1.34A1.35,1.35,0,0,0,9.54.54a1.37,1.37,0,0,0-.8.8L6.87,6.42a.12.12,0,0,1-.11.09l-5.44.21A1.34,1.34,0,0,0,0,8.08,1.37,1.37,0,0,0,.54,9.13L4.8,12.48a.15.15,0,0,1,0,.14l-1.47,5.2a1.34,1.34,0,0,0,2,1.48l4.5-3a.14.14,0,0,1,.15,0l4.5,3A1.34,1.34,0,0,0,16.42,19a1.32,1.32,0,0,0,.19-1.14l-1.48-5.22a.15.15,0,0,1,0-.14Z" />
);
const IconPasswordEyeOpen = () => (
    <path d="M0,10c1-4.7,5.2-8,10-8c4.8,0,9,3.3,10,8c-1,4.7-5.2,8-10,8C5.2,18,1,14.7,0,10z M10,15 c2.8,0,5-2.2,5-5s-2.2-5-5-5s-5,2.2-5,5S7.2,15,10,15z M10,13c1.7,0,3-1.3,3-3s-1.3-3-3-3s-3,1.3-3,3S8.3,13,10,13z" />
);
const IconDuplicate = () => (
    <path d="M15,17.33h-1.33v1.33H2.67V5h1.33v-1.33H1.33V20H15v-2.67h0ZM5.33,0V16h13.33V5.72L12.94,0H5.33Zm12,14.67H6.67V1.33h4.33V7.67h6.33v7Zm0-8.33h-5V1.33h.06l4.94,4.94v.06Z" />
);
const IconPin = () => (
    <path d="M17.5,15.6L20,20l-4.4-2.5l0-0.1l-3.3-3.3l-3,2.9c-0.5,0.5-1.2,0.5-1.9-0.2v-3.5l-5-5.2L2.2,8.4 C2,8.6,1.6,8.8,1.3,8.8C1,8.8,0.6,8.6,0.4,8.4C0.1,8.1,0,7.8,0,7.5c0-0.3,0.1-0.7,0.4-0.9l6.2-6.2C6.8,0.1,7.1,0,7.5,0 c0.3,0,0.7,0.1,0.9,0.4c0.2,0.2,0.4,0.6,0.4,0.9c0,0.3-0.1,0.7-0.4,0.9L8.1,2.5l5.3,5l3.5,0c0.7,0.7,0.7,1.3,0.2,1.8l-2.9,2.9 l3.3,3.3L17.5,15.6z" />
);
const IconLockClosed = () => (
    <path d="M14.7,7.3H14V4c0-2.2-1.8-4-4-4S6,1.8,6,4v3.3H5.3c-1.5,0-2.7,1.2-2.7,2.7v7.3c0,1.5,1.2,2.7,2.7,2.7h9.3 c1.5,0,2.7-1.2,2.7-2.7V10C17.3,8.5,16.1,7.3,14.7,7.3z M12.7,7.3H7.3V4c0-1.5,1.2-2.7,2.7-2.7s2.7,1.2,2.7,2.7V7.3z" />
);
const IconLockOpen = () => (
    <path d="M14.7,7.3H7.3V4c0-1.5,1.2-2.7,2.7-2.7s2.7,1.2,2.7,2.7c0,0.4,0.3,0.7,0.7,0.7C13.7,4.7,14,4.4,14,4 c0-2.2-1.8-4-4-4S6,1.8,6,4v3.3H5.3c-1.5,0-2.7,1.2-2.7,2.7v7.3c0,1.5,1.2,2.7,2.7,2.7h9.3c1.5,0,2.7-1.2,2.7-2.7V10 C17.3,8.5,16.1,7.3,14.7,7.3z" />
);
const IconDrag = () => (
    <path d="M4,0h4v4H4V0z M12,0h4v4h-4V0z M4,8h4v4H4V8z M12,8h4v4h-4V8z M4,16h4v4H4V16z M12,16h4v4h-4V16z" />
);
const IconSettings = () => (
    <path d="M10,6.5c-1.9,0-3.5,1.6-3.5,3.5s1.6,3.5,3.5,3.5s3.5-1.6,3.5-3.5S11.9,6.5,10,6.5z M17.5,10 c0,0.3,0,0.6-0.1,1l2.1,1.7c0.2,0.2,0.2,0.4,0.1,0.6l-2,3.5c-0.1,0.2-0.4,0.3-0.6,0.2l-2.5-1c-0.5,0.4-1.1,0.7-1.7,1l-0.4,2.6 c0,0.2-0.3,0.4-0.5,0.4H8c-0.2,0-0.5-0.2-0.5-0.4l-0.4-2.6c-0.6-0.3-1.2-0.6-1.7-1l-2.5,1c-0.2,0.1-0.5,0-0.6-0.2l-2-3.5 c-0.1-0.2-0.1-0.5,0.1-0.6L2.6,11c0-0.3-0.1-0.7-0.1-1c0-0.3,0-0.6,0.1-1L0.4,7.4C0.3,7.2,0.2,6.9,0.3,6.7l2-3.5 C2.5,3.1,2.7,3,2.9,3.1l2.5,1c0.5-0.4,1.1-0.7,1.7-1l0.4-2.6C7.5,0.2,7.7,0,8,0h4c0.2,0,0.5,0.2,0.5,0.4l0.4,2.6 c0.6,0.3,1.2,0.6,1.7,1l2.5-1c0.2-0.1,0.5,0,0.6,0.2l2,3.5c0.1,0.2,0.1,0.5-0.1,0.6L17.4,9C17.5,9.3,17.5,9.7,17.5,10z" />
);
const IconWidget = () => (
    <path d="M0,0v6.7h20V0H0z M17.8,4.4H2.2V2.2h15.6V4.4z M0,20h8.9V8.9H0V20z M2.2,11.1h4.4v6.7H2.2V11.1z M11.1,8.9H20v2.2h-8.9V8.9z M11.1,13.3H20v2.2h-8.9V13.3z M11.1,17.8H20V20h-8.9V17.8z" />
);
const IconBox = () => (
    <path id="box" d="M10.8,20l8-4,.8-12-8.8,2.4v13.6ZM1.2,16l8,4V6.4L.4,4l.8,12ZM10,0L.4,2.4l9.6,2.4,9.6-2.4L10,0Z" />
);
const IconCode = () => (
    <path d="M20,10l-5.78,5.78-1.16-1.16,4.61-4.62-4.61-4.62,1.16-1.16,5.78,5.78ZM0,10L5.78,4.22l1.16,1.16L2.34,10l4.61,4.62-1.16,1.16L0,10Zm7.05,7.83L11.36,1.74l1.59,.43-4.31,16.08-1.59-.43Z" />
);
const IconSave = () => (
    <path d="M10.78,1.09h2.8V6.89h-2.8V1.09Zm9.01,.59h0l-1.41-1.41c-.12-.13-.29-.21-.48-.21h-2.47c-.08,.01-.15,.04-.21,.1-.05,.06-.09,.13-.09,.21h0V7.2h0c-.01,.35-.3,.62-.64,.62H5.18c-.33-.02-.6-.29-.61-.62h0V.36h-.01c-.02-.08-.05-.14-.11-.2-.08-.08-.19-.12-.3-.1h0s-3.5,0-3.5,0C.29,.05,0,.34,0,.7,0,.7,0,.7,0,.7V19.31c0,.36,.29,.64,.64,.65H2.69V11.03h0c.01-.3,.23-.55,.52-.61h.04s.06,0,.09,0h13.35c.34,.01,.61,.28,.62,.62h0v8.92h2.05c.36,0,.65-.29,.65-.65V2.15c0-.18-.08-.35-.21-.48h0Z" />
);
const IconCloseThin = () => (
    <path d="M19,18.27a.48.48,0,0,1,0,.7.48.48,0,0,1-.7,0L10,10.71,1.73,19A.48.48,0,0,1,1,19a.48.48,0,0,1,0-.7L9.29,10,1,1.73A.48.48,0,0,1,1,1a.48.48,0,0,1,.7,0L10,9.29,18.27,1A.48.48,0,0,1,19,1a.48.48,0,0,1,0,.7L10.71,10Z" />
);
const IconSun = () => (
    <path d="M11,20C10.3,20,9.7,20,9,20l0.2-2c0.5,0.1,1.1,0.1,1.6,0L11,20L11,20z M7.1,19.6l0.6-1.9c-0.5-0.2-1-0.4-1.4-0.6l-0.9,1.8 C5.9,19.1,6.5,19.4,7.1,19.6L7.1,19.6z M3.7,17.7l1.3-1.5c-0.4-0.3-0.8-0.7-1.1-1.1l-1.5,1.3C2.7,16.8,3.1,17.3,3.7,17.7z M1.2,14.7 l1.8-0.9c-0.2-0.5-0.4-0.9-0.6-1.4l-1.9,0.6C0.6,13.5,0.9,14.1,1.2,14.7L1.2,14.7z M0,11l2-0.2C2,10.3,2,9.7,2,9.2L0,9 C0,9.7,0,10.3,0,11L0,11z M0.4,7.1l1.9,0.6c0.2-0.5,0.4-1,0.6-1.4L1.2,5.3C0.9,5.9,0.6,6.5,0.4,7.1L0.4,7.1z M2.3,3.7l1.5,1.3 c0.3-0.4,0.7-0.8,1.1-1.1L3.7,2.3C3.2,2.7,2.7,3.1,2.3,3.7L2.3,3.7z M5.3,1.2l0.9,1.8c0.5-0.2,0.9-0.4,1.4-0.6L7.1,0.4 C6.5,0.6,5.9,0.9,5.3,1.2L5.3,1.2z M9,0C9.7,0,10.3,0,11,0l-0.2,2C10.3,2,9.7,2,9.2,2L9,0L9,0z M12.9,0.4l-0.6,1.9 c0.5,0.2,1,0.4,1.4,0.6l0.9-1.8C14.1,0.9,13.5,0.6,12.9,0.4L12.9,0.4z M16.3,2.3l-1.3,1.5c0.4,0.3,0.8,0.7,1.1,1.1l1.5-1.3 C17.3,3.1,16.9,2.7,16.3,2.3L16.3,2.3z M18.8,5.3l-1.8,0.9c0.2,0.5,0.4,0.9,0.6,1.4l1.9-0.6C19.4,6.5,19.1,5.9,18.8,5.3L18.8,5.3z M20,9l-2,0.2c0.1,0.5,0.1,1.1,0,1.6l2,0.2C20,10.3,20,9.7,20,9L20,9z M19.6,12.9l-1.9-0.6c-0.2,0.5-0.4,1-0.6,1.4l1.8,0.9 C19.1,14.1,19.4,13.5,19.6,12.9L19.6,12.9z M17.7,16.3l-1.5-1.3c-0.3,0.4-0.7,0.8-1.1,1.1l1.3,1.5C16.9,17.3,17.3,16.9,17.7,16.3z M14.7,18.8l-0.9-1.8c-0.5,0.2-0.9,0.4-1.4,0.6l0.6,1.9C13.5,19.4,14.1,19.1,14.7,18.8L14.7,18.8z M10,7c-1.7,0-3,1.3-3,3s1.3,3,3,3 s3-1.3,3-3S11.7,7,10,7z M10,11c-0.6,0-1-0.4-1-1c0-0.6,0.4-1,1-1c0.6,0,1,0.4,1,1C11,10.6,10.6,11,10,11z M10,4c-3.3,0-6,2.7-6,6 s2.7,6,6,6s6-2.7,6-6S13.3,4,10,4z M10,14c-2.2,0-4-1.8-4-4c0-2.2,1.8-4,4-4c2.2,0,4,1.8,4,4C14,12.2,12.2,14,10,14z" />
);
const IconShare = () => (
    <path
        d="M16.7,6.7c1.8,0,3.3-1.5,3.3-3.3S18.5,0,16.7,0c-1.8,0-3.3,1.5-3.3,3.3c0,0.1,0,0.3,0,0.4L5.6,7.6C4.3,6.3,2.2,6.4,0.9,7.7
	s-1.2,3.4,0.1,4.7c1.3,1.2,3.3,1.2,4.6,0l7.7,3.9c-0.2,1.8,1.1,3.5,2.9,3.7c1.8,0.2,3.5-1.1,3.7-2.9c0.2-1.8-1.1-3.5-2.9-3.7
	c-1-0.1-2,0.2-2.7,0.9l-7.7-3.9c0-0.3,0-0.5,0-0.8l7.7-3.9C15,6.3,15.8,6.7,16.7,6.7z"
    />
);

const IconDollar = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z" />
    </svg>
);

const IconRenew = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
        <path d="M 15 3 C 12.031398 3 9.3028202 4.0834384 7.2070312 5.875 A 1.0001 1.0001 0 1 0 8.5058594 7.3945312 C 10.25407 5.9000929 12.516602 5 15 5 C 20.19656 5 24.450989 8.9379267 24.951172 14 L 22 14 L 26 20 L 30 14 L 26.949219 14 C 26.437925 7.8516588 21.277839 3 15 3 z M 4 10 L 0 16 L 3.0507812 16 C 3.562075 22.148341 8.7221607 27 15 27 C 17.968602 27 20.69718 25.916562 22.792969 24.125 A 1.0001 1.0001 0 1 0 21.494141 22.605469 C 19.74593 24.099907 17.483398 25 15 25 C 9.80344 25 5.5490109 21.062074 5.0488281 16 L 8 16 L 4 10 z" />
    </svg>
);

const IconStart = () => <path d="M6 4l10 6-10 6z" />;

const IconCopy = () => (
    <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 105.02 122.88"
        style={{ enableBackground: "new 0 0 105.02 122.88" }}
        xmlSpace="preserve"
    >
        <g>
            <path d="M5.32,14.64h20.51V5.32v0h0.01c0-1.47,0.6-2.8,1.56-3.76c0.95-0.95,2.28-1.55,3.75-1.55V0h0h39.61h1.22l0.88,0.88 l31.29,31.41l0.87,2.09v69.2v0h-0.01c0,1.47-0.59,2.8-1.55,3.76h-0.01c-0.95,0.96-2.28,1.55-3.75,1.55v0.01h0H79.19v8.65v0h-0.01 c0,1.47-0.59,2.8-1.55,3.76h-0.01c-0.96,0.95-2.28,1.55-3.75,1.55v0.01h0H5.32h0v-0.01c-1.47,0-2.8-0.6-3.76-1.56 c-0.95-0.96-1.55-2.28-1.55-3.75H0v0V19.97v0h0.01c0-1.47,0.6-2.8,1.56-3.76c0.95-0.95,2.28-1.55,3.75-1.55L5.32,14.64L5.32,14.64 L5.32,14.64z M31.76,14.64h13.17h1.22l0.88,0.88l31.29,31.41l0.87,2.09v53.95h19.89V36.24H74.73h0v0c-1.78,0-3.39-0.74-4.56-1.94 c-1.17-1.19-1.9-2.84-1.9-4.65h0v0V5.94H31.76V14.64L31.76,14.64z M68.39,2.97h2.37l31.29,31.41v1.74H74.73 c-3.49,0-6.35-2.92-6.35-6.48V2.97L68.39,2.97z M73.26,50.88H48.91h0v0c-1.78,0-3.39-0.74-4.56-1.94c-1.17-1.19-1.9-2.84-1.9-4.65 h0v0V20.58H25.83H5.94v96.36h67.32v-8.04v-2.97V50.88L73.26,50.88z" />
        </g>
    </svg>
);

const IconShareCheck = () => (
    <path
        d="M16.9,3.2c-1.7,0-3.1,1.4-3.1,3.1c0,0.5,0.1,1.1,0.4,1.5l-5.8,5.8L5.8,11c0.2-0.4,0.4-0.9,0.4-1.5c0-1.7-1.4-3.1-3.1-3.1
	S0,7.8,0,9.5s1.4,3.1,3.1,3.1c0.4,0,0.7-0.1,1-0.2l4.3,4.3L16,9.3c0.3,0.1,0.6,0.2,1,0.2C18.6,9.4,20,8,20,6.3
	C20,4.6,18.6,3.2,16.9,3.2z M1.1,9.5c0-1.1,0.9-2,2-2c1.1,0,2,0.9,2,2c0,1.1-0.9,2-2,2C2,11.6,1.1,10.6,1.1,9.5z M16.9,8.3
	c-1.1,0-2-0.9-2-2s0.9-2,2-2c1.1,0,2,0.9,2,2S18,8.3,16.9,8.3z"
    />
);

const IconBug = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 324.274 324.274"
        xmlSpace="preserve"
        width="15px"
        height="15px"
    >
        <g>
            <path d="M34.419,298V22h138.696h0.841v33.411c0,8.301,6.753,15.055,15.053,15.055h33.154v88.5c2.443-0.484,4.957-0.75,7.528-0.75   c5.087,0,9.962,0.994,14.472,2.804V64.006c0-1.326-0.526-2.598-1.464-3.536L183.694,1.464C182.755,0.527,181.484,0,180.158,0   H27.472c-8.3,0-15.053,6.753-15.053,15.054v289.893c0,8.301,6.753,15.054,15.053,15.054h111.884   c-1.256-6.713,1.504-13.831,7.559-17.83c2.341-1.546,4.692-2.919,7.034-4.17H34.419z" />
            <path d="M308.487,310.515c-12.254-8.092-25.057-11.423-33.599-12.795c6.02-9.685,9.564-21.448,9.564-34.129   c0-9.12-1.824-17.889-5.174-25.781c8.22-1.738,18.908-5.176,29.209-11.98c3.457-2.283,4.408-6.935,2.126-10.392   c-2.283-3.456-6.936-4.407-10.392-2.125c-10.742,7.094-22.229,9.723-29.102,10.698c-3.459-4.387-7.5-8.249-12.077-11.394   c0.859-3.081,1.294-6.265,1.294-9.509c0-17.861-13.062-32.393-29.117-32.393c-16.055,0-29.115,14.531-29.115,32.393   c0,3.244,0.435,6.428,1.294,9.509c-4.577,3.145-8.618,7.007-12.077,11.394c-6.873-0.975-18.358-3.603-29.102-10.698   c-3.456-2.282-8.108-1.331-10.392,2.125c-2.282,3.456-1.331,8.109,2.126,10.392c10.301,6.803,20.988,10.241,29.208,11.979   c-3.351,7.893-5.175,16.661-5.175,25.781c0,12.681,3.544,24.444,9.563,34.129c-8.541,1.372-21.343,4.703-33.597,12.794   c-3.456,2.283-4.408,6.935-2.126,10.392c1.442,2.184,3.83,3.368,6.266,3.368c1.419,0,2.854-0.402,4.126-1.242   c16.62-10.975,35.036-11.269,35.362-11.272c0.639-0.002,1.255-0.093,1.847-0.245c8.877,7.447,19.884,11.861,31.791,11.861   c11.907,0,22.914-4.415,31.791-11.861c0.598,0.153,1.22,0.244,1.865,0.245c0.183,0,18.499,0.148,35.346,11.272   c1.272,0.84,2.707,1.242,4.126,1.242c2.434,0,4.823-1.184,6.266-3.368C312.895,317.45,311.943,312.797,308.487,310.515z    M238.719,296.005c0,4.142-3.357,7.5-7.5,7.5c-4.142,0-7.5-3.358-7.5-7.5v-64.83c0-4.142,3.358-7.5,7.5-7.5   c4.143,0,7.5,3.358,7.5,7.5V296.005z" />
            <path d="M143.627,49.624h-78c-4.418,0-8,3.582-8,8c0,4.418,3.582,8,8,8h78c4.418,0,8-3.582,8-8   C151.627,53.206,148.045,49.624,143.627,49.624z" />
            <path d="M143.627,99.624h-78c-4.418,0-8,3.582-8,8c0,4.419,3.582,8,8,8h78c4.418,0,8-3.581,8-8   C151.627,103.206,148.045,99.624,143.627,99.624z" />
            <path d="M143.627,149.624h-78c-4.418,0-8,3.582-8,8c0,4.419,3.582,8,8,8h78c4.418,0,8-3.581,8-8   C151.627,153.206,148.045,149.624,143.627,149.624z" />
        </g>
    </svg>
);

/**
 * Export them all for an easier import
 */
export const IconLib = {
    IconArrowDown,
    IconArrowLeft,
    IconArrowRight,
    IconArrowUp,
    IconAsterisk,
    IconBell,
    IconBox,
    IconCardView,
    IconCheck,
    IconClickOut,
    IconClose,
    IconCloseThin,
    IconCode,
    IconColumns,
    IconDesctiption,
    IconDollar,
    IconDot,
    IconDrag,
    IconDuplicate,
    IconEdit,
    IconHamburger,
    IconHome,
    IconLink,
    IconLockClosed,
    IconLockOpen,
    IconMagnifier,
    IconOffer,
    IconPasswordEyeOpen,
    IconPin,
    IconPlus,
    IconRect,
    IconRenew,
    IconReset,
    IconSave,
    IconSettings,
    IconStar,
    IconStarEmpty,
    IconStarHalf,
    IconSun,
    IconSwitch,
    IconThreeDot,
    IconTime,
    IconTrash,
    IconUpload,
    IconWarning,
    IconWarningSign,
    IconWidget,
    IconStart,
    IconCopy,
    IconShareCheck,
    IconShare,
    IconBug,
};
