import { useState, ThemeSelector, useSelector, LoadingAnimation } from "V3";
import { useEffect, Snackbar, React, Spacer } from "V3";
import {
    componentSelector,
    openSnackBarIsolated,
    closeSnackBarIsolated,
    cleanUpIsolated,
    cleanUpStateIsolated,
    // setPageStateIsolated,
    // mergeSections,
} from "./V3LayoutCreatorFunctions.js";

export const V3LayoutCreator = ({
    pageSettings,
    pageState,
    setPageState,
    mappings,
    setMappings,
    dynamicVariables,
    ...props
}) => {
    const { metadata } = useSelector((state) => state);
    const [isLoading, setIsLoading] = useState(true);
    const [snackBarState, setSnackBarState] = useState();

    /**
     * Isolated Functions
     */
    const openSnackBar = (msg, type, persistent) => openSnackBarIsolated(msg, type, persistent, setSnackBarState);
    const closeSnackBar = () => closeSnackBarIsolated(snackBarState, setSnackBarState);
    const cleanUp = () => cleanUpIsolated(setPageState, setIsLoading, setSnackBarState, pageSettings);
    const cleanUpState = () => cleanUpStateIsolated({ setPageState, pageSettings });

    /**
     * Effects
     */
    useEffect(() => cleanUp(), [pageSettings?.assets?.pageName]);

    /**
     * LAYOUT ASSETS
     */
    const layoutAssets = {
        pageState,
        setPageState,
        openSnackBar,
        setIsLoading,
        metadata,
        // pageStateRef,
        // setPageStateFn,
        componentSelector,
        cleanUpState,
        dynamicVariables,
        mappings,
        setMappings,
    };

    /**
     * RETURN
     */
    if (!metadata || metadata.length < 1 || !pageState) return null;
    return (
        <ThemeSelector>
            <div data-info="coreUiFix" style={{ marginTop: "-2rem" }}>
                {/* GLOBAL COMPONENTS */}
                <LoadingAnimation isLoading={isLoading} setIsLoading={setIsLoading} />
                <Snackbar snackBarState={snackBarState} handleClose={closeSnackBar} />
                {/* CHILDRENS */}
                {Object.entries(pageState).map(([key, obj]) => {
                    if (key === "assets" || obj.hide) return null;
                    const NewComponent = obj.component || componentSelector(obj.componentName);
                    return (
                        <>
                            <NewComponent sectionName={key} layoutAssets={layoutAssets} {...obj.objectProps} />
                            <Spacer space={30} />
                        </>
                    );
                })}
            </div>
        </ThemeSelector>
    );
};
