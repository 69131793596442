// https://github.com/clauderic/react-sortable-hoc
import { AppContext, useContext, Icon, IconLib, useState, useRef, _, useEffect } from "V3";
import {
    Container,
    UploadZone,
    OrderArea,
    ImageItem,
    Image,
    UploadZoneHiddenInput,
    Label,
    LabelInfo,
    CloseIconDiv,
} from "./V3ImageSelector.styled.js";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";

export const V3ImageSelector = ({
    name,
    value,
    onChange,
    customUpload,
    preventAddNew,
    square,
    placeholder = "Drag your images here OR click to upload.",
    ...props
}) => {
    /**
     * onChange
     */
    const updateValues = (val) => onChange(val, name);

    /**
     * onSortEnd
     */
    const onSortEnd = ({ oldIndex, newIndex }) => updateValues(arrayMoveImmutable(value, oldIndex, newIndex));

    /**
     * get Images from event
     */
    const getImages = (e) => {
        const allowedFileFormats = ["image/jpeg", "image/jpg", "image/png"];

        if (!e) return;

        let files = [];

        if (e.target && e.target.files) {
            files = e.target.files;
        } else if (e.dataTransfer && e.dataTransfer.files) {
            files = e.dataTransfer.files;
        }

        if (!files.length) return;

        const safeValue = Array.isArray(value) ? value : [];

        const newImageItems = [];
        let currentPosition = safeValue.length;

        _.forEach(files, (file) => {
            if (!allowedFileFormats.includes(file.type)) return;
            currentPosition += 1;
            newImageItems.push({
                position: currentPosition,
                file,
                value: file.name,
                path: URL.createObjectURL(file),
            });
        });

        updateValues([...safeValue, ...newImageItems]);
    };

    /**
     * delete or mark as deleted an image
     */
    const deleteAnImage = (item, index) => {
        const duplicatedItems = _.cloneDeep(value);
        // if (item.isManual) {
        duplicatedItems.splice(index, 1);
        // } else {
        //     duplicatedItems[index].deleted = true;
        // }
        updateValues(duplicatedItems);
    };

    useEffect(() => {
        const handlePaste = (e) => {
            if (!e.clipboardData) return;

            const items = e.clipboardData.items;
            if (!items) return;

            const newFiles = [];
            for (const item of items) {
                if (item.type.indexOf("image") === 0) {
                    const file = item.getAsFile();
                    if (file) newFiles.push(file);
                }
            }

            if (newFiles.length > 0) {
                const syntheticEvent = {
                    target: { files: newFiles },
                };
                getImages(syntheticEvent);
            }
        };

        document.addEventListener("paste", handlePaste);
        return () => {
            document.removeEventListener("paste", handlePaste);
        };
    }, []);

    /**
     * Return
     */
    return (
        <Container>
            {customUpload === "true" && (
                <>
                    <Label>Offer Thumbs</Label>
                    <LabelInfo>The single image size has to be lower than 200 kb.</LabelInfo>
                </>
            )}
            <SortableList
                items={value}
                onSortEnd={onSortEnd}
                axis="x"
                lockAxis="x"
                square={square}
                {...{ getImages, deleteAnImage, placeholder }}
                preventAddNew={preventAddNew}
            />
        </Container>
    );
};

const UploadFile = (props) => {
    const { getImages, placeholder } = props || {};
    const draggableZone = useRef();
    const input = useRef();
    const [isHover, setHover] = useState();
    const { isDarkMode } = useContext(AppContext);

    /**
     * file Input event and drop event
     */
    const fileInputEvent = (e) => getImages(e);
    const dropEvent = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!e || !e.dataTransfer || !e.dataTransfer.files || !e.dataTransfer.files.length) {
            console.warn("No files found in dropEvent.");
            return;
        }
        getImages(e);
    };

    /**
     * Allow Drop
     */
    const allowDrop = (e) => e.preventDefault();

    /**
     * Return
     */
    return (
        <>
            <UploadZoneHiddenInput type="file" multiple="multiple" ref={input} onChange={fileInputEvent} />
            <UploadZone
                ref={draggableZone}
                htmlFor={input}
                onDragEnter={() => setHover(true)}
                onDragLeave={() => setHover(true)}
                onMouseOut={() => setHover()}
                onDrop={dropEvent}
                onDragOver={(e) => allowDrop(e)}
                onClick={() => input.current.click()}
                {...{ isHover, isDarkMode }}
                style={{ height: "200px" }}
            >
                <Icon icon={IconLib.IconPlus} color={isDarkMode ? "white" : "black"} width={75} />
                <div style={{ textAlign: "center" }}>{placeholder}</div>
            </UploadZone>
        </>
    );
};

const SortableList = SortableContainer(({ items, getImages, deleteAnImage, preventAddNew, square, placeholder }) => {
    return (
        <OrderArea>
            {!items
                ? null
                : items.map((item, index) => {
                      return (
                          <SortableItem
                              key={`item-${item}`}
                              indexNo={index}
                              {...{ deleteAnImage, item, index, square }}
                          />
                      );
                  })}
            {!preventAddNew && <UploadFile {...{ getImages, placeholder }} />}
        </OrderArea>
    );
});

const SortableItem = SortableElement(({ item, deleteAnImage, indexNo, square }) => {
    const deleted = item?.deleted;
    const imRef = useRef();
    const { isDarkMode } = useContext(AppContext);

    /**
     * Im Error Handler
     */
    const imageErrorHandler = () => {
        imRef.current.src = require("V3/Pages/CampaignBoxes/Offers/EditOffer/fxSquare.svg").default;
        imRef.current.onError = null;
    };
    /**
     * Return
     */
    return (
        <ImageItem {...{ isDarkMode, square, deleted }}>
            <CloseIconDiv onClick={() => deleteAnImage(item, indexNo)}>
                <Icon icon={IconLib.IconClose} color={isDarkMode ? "white" : "black"} width={20} />
            </CloseIconDiv>
            <Image
                ref={imRef}
                onError={imageErrorHandler}
                src={item.small || item.path}
                alt="thumb"
                style={square && { width: "300px", height: "300px" }}
            />
            {/* <Image src={item.small || item.path} alt="thumb" /> */}
        </ImageItem>
    );
});
