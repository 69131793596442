import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

export const SwitchButton = (props) => {
    const { label, clickHandler, checked, disabled = false } = props;

    return (
        <FormControlLabel
            control={
                <Switch checked={checked} onChange={clickHandler} name={label} color="primary" disabled={disabled} />
            }
            labelPlacement="start"
            label={label}
        />
    );
};
