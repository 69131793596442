import { useDispatch } from "react-redux";
import { metadata as metadataActions } from "store/actions/metadata";

export function useRefreshMetadata() {
    const dispatch = useDispatch();

    const refreshMetadata = (queryArgs, callback) => {
        setTimeout(() => {
            dispatch(metadataActions.refreshMetaData(queryArgs));
            // If callback is passed and is a function, call it
            if (callback && typeof callback === "function") {
                callback();
            }
        }, 0);
    };

    return refreshMetadata;
}
